@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-Bold.eot');
    src: url('HankenGrotesk-Bold.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-Bold.woff2') format('woff2'),
        url('HankenGrotesk-Bold.woff') format('woff'),
        url('HankenGrotesk-Bold.ttf') format('truetype'),
        url('HankenGrotesk-Bold.svg#HankenGrotesk-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-BlackItalic.eot');
    src: url('HankenGrotesk-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-BlackItalic.woff2') format('woff2'),
        url('HankenGrotesk-BlackItalic.woff') format('woff'),
        url('HankenGrotesk-BlackItalic.ttf') format('truetype'),
        url('HankenGrotesk-BlackItalic.svg#HankenGrotesk-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-Black.eot');
    src: url('HankenGrotesk-Black.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-Black.woff2') format('woff2'),
        url('HankenGrotesk-Black.woff') format('woff'),
        url('HankenGrotesk-Black.ttf') format('truetype'),
        url('HankenGrotesk-Black.svg#HankenGrotesk-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-Italic.eot');
    src: url('HankenGrotesk-Italic.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-Italic.woff2') format('woff2'),
        url('HankenGrotesk-Italic.woff') format('woff'),
        url('HankenGrotesk-Italic.ttf') format('truetype'),
        url('HankenGrotesk-Italic.svg#HankenGrotesk-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-BoldItalic.eot');
    src: url('HankenGrotesk-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-BoldItalic.woff2') format('woff2'),
        url('HankenGrotesk-BoldItalic.woff') format('woff'),
        url('HankenGrotesk-BoldItalic.ttf') format('truetype'),
        url('HankenGrotesk-BoldItalic.svg#HankenGrotesk-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-Light.eot');
    src: url('HankenGrotesk-Light.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-Light.woff2') format('woff2'),
        url('HankenGrotesk-Light.woff') format('woff'),
        url('HankenGrotesk-Light.ttf') format('truetype'),
        url('HankenGrotesk-Light.svg#HankenGrotesk-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-ExtraLight.eot');
    src: url('HankenGrotesk-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-ExtraLight.woff2') format('woff2'),
        url('HankenGrotesk-ExtraLight.woff') format('woff'),
        url('HankenGrotesk-ExtraLight.ttf') format('truetype'),
        url('HankenGrotesk-ExtraLight.svg#HankenGrotesk-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-ExtraBoldItalic.eot');
    src: url('HankenGrotesk-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-ExtraBoldItalic.woff2') format('woff2'),
        url('HankenGrotesk-ExtraBoldItalic.woff') format('woff'),
        url('HankenGrotesk-ExtraBoldItalic.ttf') format('truetype'),
        url('HankenGrotesk-ExtraBoldItalic.svg#HankenGrotesk-ExtraBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-ExtraLightItalic.eot');
    src: url('HankenGrotesk-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-ExtraLightItalic.woff2') format('woff2'),
        url('HankenGrotesk-ExtraLightItalic.woff') format('woff'),
        url('HankenGrotesk-ExtraLightItalic.ttf') format('truetype'),
        url('HankenGrotesk-ExtraLightItalic.svg#HankenGrotesk-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-LightItalic.eot');
    src: url('HankenGrotesk-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-LightItalic.woff2') format('woff2'),
        url('HankenGrotesk-LightItalic.woff') format('woff'),
        url('HankenGrotesk-LightItalic.ttf') format('truetype'),
        url('HankenGrotesk-LightItalic.svg#HankenGrotesk-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-ExtraBold.eot');
    src: url('HankenGrotesk-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-ExtraBold.woff2') format('woff2'),
        url('HankenGrotesk-ExtraBold.woff') format('woff'),
        url('HankenGrotesk-ExtraBold.ttf') format('truetype'),
        url('HankenGrotesk-ExtraBold.svg#HankenGrotesk-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-Medium.eot');
    src: url('HankenGrotesk-Medium.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-Medium.woff2') format('woff2'),
        url('HankenGrotesk-Medium.woff') format('woff'),
        url('HankenGrotesk-Medium.ttf') format('truetype'),
        url('HankenGrotesk-Medium.svg#HankenGrotesk-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-Thin.eot');
    src: url('HankenGrotesk-Thin.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-Thin.woff2') format('woff2'),
        url('HankenGrotesk-Thin.woff') format('woff'),
        url('HankenGrotesk-Thin.ttf') format('truetype'),
        url('HankenGrotesk-Thin.svg#HankenGrotesk-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-ThinItalic.eot');
    src: url('HankenGrotesk-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-ThinItalic.woff2') format('woff2'),
        url('HankenGrotesk-ThinItalic.woff') format('woff'),
        url('HankenGrotesk-ThinItalic.ttf') format('truetype'),
        url('HankenGrotesk-ThinItalic.svg#HankenGrotesk-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-MediumItalic.eot');
    src: url('HankenGrotesk-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-MediumItalic.woff2') format('woff2'),
        url('HankenGrotesk-MediumItalic.woff') format('woff'),
        url('HankenGrotesk-MediumItalic.ttf') format('truetype'),
        url('HankenGrotesk-MediumItalic.svg#HankenGrotesk-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-Regular.eot');
    src: url('HankenGrotesk-Regular.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-Regular.woff2') format('woff2'),
        url('HankenGrotesk-Regular.woff') format('woff'),
        url('HankenGrotesk-Regular.ttf') format('truetype'),
        url('HankenGrotesk-Regular.svg#HankenGrotesk-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-SemiBoldItalic.eot');
    src: url('HankenGrotesk-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-SemiBoldItalic.woff2') format('woff2'),
        url('HankenGrotesk-SemiBoldItalic.woff') format('woff'),
        url('HankenGrotesk-SemiBoldItalic.ttf') format('truetype'),
        url('HankenGrotesk-SemiBoldItalic.svg#HankenGrotesk-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Hanken Grotesk';
    src: url('HankenGrotesk-SemiBold.eot');
    src: url('HankenGrotesk-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('HankenGrotesk-SemiBold.woff2') format('woff2'),
        url('HankenGrotesk-SemiBold.woff') format('woff'),
        url('HankenGrotesk-SemiBold.ttf') format('truetype'),
        url('HankenGrotesk-SemiBold.svg#HankenGrotesk-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

