.kubini-account-page{
    position: relative;
    width: 100%;
    padding: 2em 1.5em 1.5em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
  }
  .kubini-account-banner{
    position: relative;
    width: 100%;
  }
  .kubini-account-avatar{
    position: relative;
    width: 100%;
    max-width: 15em;
    min-height: 20em;
    height: 100%;
    background: var(--background_tint);
    overflow: hidden;
    border-radius: var(--border_md);
    input{
      display: none;
    }

    @include media('<=990px'){
      max-width: 100%;
    }
  }
  label.kubini-account-avatar-form-image{
    cursor: pointer;
  }
  .kubini-account-avatar-form-image{
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 20em;
    @extend %flex-center;
    overflow: hidden;
    img{
      min-height: 20em;
      @extend %image-center;
    }
    i{
      font-size: 3em;
    }
  }
  .kubini-account-password{
    position: relative;
    width: 100%;
    max-width: 20em;
    height: 100%;
    min-height: 20em;
    background: var(--background_tint);
    border-radius: var(--border_md);
    padding: 1em;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: .3s ease-in-out;
    @include media('<=990px'){
      max-width: 100%;
    }
    &.is--full{
      max-width: 100%;
    }
    &:hover{
      background: var(--primary_rgba);
      i{
        color: var(--primary);
      }
    }
    &__icon{
      width: 100%;
      text-align: var(--text-align);
      i{
        font-size: 3em;
      }
    }
    &__text{
      position: relative;
      width: 100%;
      text-align: var(--text-align);
      p{
        color: var(--color_tint);
        font-size: 1em;
      }
      strong{
        font-size: 2em;
      }
    }
  }
  .kubini-account-logout{
    position: relative;
    width: 100%;
    max-width: 20em;
    height: 100%;
    min-height: 20em;
    background: rgba($color: $danger, $alpha: .2);
    border-radius: var(--border_md);
    padding: 1em;
    @include media('<=990px'){
      max-width: 100%;
    }
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: .3s ease-in-out;
  
    &__icon{
      width: 100%;
      text-align: var(--text-align);
      i{
        font-size: 3em;
      }
    }
    &__text{
      position: relative;
      width: 100%;
      text-align: var(--text-align);
      p{
        color: var(--color_tint);
        font-size: 1em;
      }
      strong{
        font-size: 2em;
      }
    }
  }
  .kubini-account-infos-card{
    position: relative;
    width: 100%;
    background: var(--background_tint);
    border-radius: var(--border_md);
    padding: 1em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    &__icon{
      width: 100%;
      text-align: var(--text-align);
      i{
        font-size: 3em;
      }
    }
  }
  button.kubini-account-information{
    transition: .3s ease;
    &:hover{
      background-color: var(--primary_rgba);
    }
  }
  .kubini-account-information{
    position: relative;
    width: calc(100% - 2em - 15em - 20em);

    @include media('<=990px'){
      width: 100%;
    }
    height: 100%;
    min-height: 20em;
    background: var(--background_tint);
    border-radius: var(--border_md);
    padding: 1em;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &.type-0{
      width: calc(100% - 1em - 15em);

      @include media('<=990px'){
        width: 100%;
      }
    }
    &.type-2{
      width: calc(100% - 3em - 15em - 20em - 20em);

      @include media('<=990px'){
        width: 100%;
      }
    }
    &.type-3{
      width: calc((100% -  17em) / 2);

      @include media('<=990px'){
        width: 100%;
      }
    }
    &.type-4{
      width: calc((100% -  15em - 1em) / 2);

      @include media('<=990px'){
        width: 100%;
      }
    }
    &__icon{
      width: 100%;
      text-align: var(--text-align);
      i{
        font-size: 3em;
      }
    }
  }
  .kubini-account-information-text{
    position: relative;
    width: 100%;
    &__item{
      position: relative;
      width: 100%;
      font-size: 1em;
    }
  }
  .kubini-account-content{
    position: relative;
    width: 100%;
  }
  .kubini-account-informations,
  .kubini-account-activities,
  .kubini-account-sessions{
    position: relative;
    //width: 100%;
    width: calc(100% / 2 - .8em);
    padding: 1em;
    background: var(--background_tint);
    border-radius: var(--border_md);
    min-height: 40em;
    height: 78svh;
    display: flex;
    flex-direction: column;
    row-gap: .3em;
    &.is--full{
      width: 100%;
    }
    &__list{
      position: relative;
      width: 100%;
      height: 100%;
      overflow-y: auto;
    }
  }
  
  .kubini-account-infos{
    position: relative;
    //width: 100%;
    width: calc(100% / 2 - 0.4em);
    min-height: 40em;
    height: 78svh;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
  }
  .kubini-activity{
    position: relative;
    width: 100%;
    display: flex;
    padding: 1em;
    background: var(--background);
    gap: .5em;
    &__container{
        position: relative;
        display: flex;
        flex-direction: column;
        span{
            font-size: .9em;
            opacity: 0.8;
        }
    }
  }

  .kubini-account-bg{
    position: relative;
    width: 100%;
    height: 35em;
    background: var(--background_tint);
    border-radius: var(--border_md);
    overflow: hidden;
    img{
      @extend %image-center;
    }
  }


  .kubini-arrow{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 3em;
    height: 3em;
    background: var(--color);
    color: var(--background);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: var(--border_ultra_xl);
    z-index: 2;
    &#next{
        right: -10px;
        i{
          transform: scaleX(-1);
        }
    }
    &.slick-disabled{
      display: none;
    }
    &#prev{
        left: -10px;
        
    }
}

  .kubini-product-banner{
    position: relative;
    width: 100%;
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    &__side{
      position: relative;
      width: 100%;
      max-width: 25em;
      height: 45em;
      display: flex;
      flex-direction: column;
      row-gap: 1em;
    }
    &__ads{
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: var(--border_md);
      background: var(--background);
      overflow: hidden;
      display: flex;
      img{
          width: 100%;
          height: 100%;
          object-fit: cover;
      }
      span{
          position: absolute;
          bottom: 1em;
          left: 1em;
          background: var(--background);
          color: var(--color);
          padding: .5em 1em;
          border-radius: 1em;
          font-size: .8em;
      }
    }
  }
  .kubini-product-banner-info{
    border-radius: var(--border_md);
    background: var(--background_tint);
    border: .02em solid var(--color_rgba);
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2em;
    padding: 1em;
    &__details{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: .7em;
      h2{
        font-size: 2.1em;
      }
      a{
        text-transform: uppercase;
        font-weight: bold;
        text-decoration: underline;
        &:hover{
          color: var(--primary);
        }
      }
    }
    &__orders{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: .7em;
    }
    &__price{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: .2em;
      strong{
        font-size: 2.4em;
      }
      span{
        font-size: .8em;
        opacity: .9;
      }
    }
    &__qte{
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 1em;
      flex-wrap: wrap;
      button{
        position: relative;
        width: 2.5em;
        height: 2.5em;
        background: var(--background_tint);
        border-radius: 50%;
        border: .04em solid var(--color_rgba);
        @extend %flex-center;
        transition: .4s ease;
        &.is--disable{
          pointer-events: none;
          opacity: .4;
        }
      }
      input{
        max-width: 4em;
        text-align: center;
        color: inherit;
        background: none;
        outline: none;
        border: none;
      }
    }
    &__actions{
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: .4em;
      margin-top: 2em;
      button{
        justify-content: center;
        width: 100%;
        min-height: 3em;
      }
    }
    &__share{
      position: absolute;
      top: .5em;
      right: .5em;
      opacity: .8;
      &:hover{
        opacity: 1;
      }
      font-size: 1.2em;
    }
  }
  .kubini-product-banner-info-avis{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1em;
    flex-wrap: wrap;
    span{
      text-decoration: underline;
      opacity: .7;
    }
    &__stars{
      position: relative;
      display: flex;
      align-items: center;
      gap: .5em;
    }
    &__star{
      position: relative;
      width: 1.2em;
      height: 1.2em;
      background: var(--color);
      clip-path: polygon(50% 0%, 63% 31%, 98% 35%, 73% 58%, 79% 91%, 50% 74%, 21% 91%, 27% 57%, 2% 35%, 36% 32%);
      opacity: .5;
      &.is--active{
        opacity: 1;
      }
    }
  }
  .kubini-product-banner-images{
    position: relative;
    width: 100%;
    max-width: 57em;
    height: 45em;
    display: flex;
    flex-direction: column;
    row-gap: 1em;
  }
  .kubini-product-banner-image-main{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--background_tint);
    border-radius: var(--border_md);
    img{
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .kubini-product-banner-description{
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: var(--background_tint);
    border-radius: var(--border_md);
    padding: 1em;
    strong{
      padding-bottom: .8em;
      border-bottom: .1em solid var(--color_rgba);
      margin-bottom: 1em;
      display: block;
      width: 100%;
    }
  }
  .kubini-product-banner-image-list{
    position: relative;
    width: 100%;
  }
  .kubini-product-banner-image-lil{
    position: relative;
    padding-right: .5em;
    button{
      position: relative;
      overflow: hidden;
      width: 6.5em;
      height: 6.5em;
      padding: .4em;
      border-radius: var(--border_md);
      border: .2em solid transparent;
      &.is--active{
        border-color: var(--primary);
      }
      img{
        @extend %image-center;
        border-radius: var(--border_md);
      }
    }
  }
  
  .kubini-product-navigation{
    position: relative;
    width: 100%;
    padding: .8em;
    border-radius: var(--border_md);
    background: var(--background_tint);
    margin: 1em 0;
  
    &__container{
      position: relative;
      width: 100%;
      display: none;
      padding: 1em 0;
      min-height: 40em;
      height: 80svh;
      overflow-y: auto;
      &.is--active{
        display: block;
      }
    }
  }
  .kubini-product-navigation-nav{
    position: relative;
    width: 100%;
    padding-bottom: .8em;
    border-bottom: .1em solid var(--color_rgba);
    display: flex;
    overflow-x: auto;
    gap: .8em;
    &__item{
        position: relative;
        padding: 0 .5em;
        white-space: nowrap;
        font-size: 1em;
        &.is--active{
            font-weight: bold;
            color: var(--primary);
            &::after{
                @include pseudo-el;
                width: 100%;
                height: 0.2em;
                background: var(--primary);
                bottom: -.8em;
                left: 0;
            }
        }
    }
  }
  .kubini-product-banner-info-user{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: .8em;
    flex-wrap: wrap;
    margin-top: .8em 0;
    &__avatar{
      width: 3.5em;
      height: 3.5em;
      background-color: var(--background);
      border-radius: var(--border_ultra_xl);
      overflow: hidden;
      @extend %flex-center;
      img{
        @extend %image-center;
      }
    }
    &__info{
      display: flex;
      flex-direction: column;
    }
  }
  
  .kubini-product-form{
    position: relative;
    width: 100%;
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
  }
  .kubini-product-form-likes{
    &__btn{
      position: relative;
      width: auto;
      padding: .9em 1em;
      border: .04em solid var(--color_rgba);
      display: flex;
      gap: .8em;
      align-items: center;
      background: var(--background);
      border-radius: var(--border_md);
      font-size: 1em;
      &.is--disable{
        pointer-events: none;
        opacity: 0.4;
      }
      &.is--active{
        background: var(--primary_rgba);
        border-color: var(--primary);
      }
    }
  }
  .kubini-product-form-comments{
    position: relative;
    width: 100%;
    max-width: 42em;
    display: flex;
    gap: .5em;
    &__field{
      position: relative;
      width: 100%;
      textarea{
        position: relative;
        width: 100%;
        padding: .9em 1em;
        border: .04em solid var(--color_rgba);
        min-height: 3.2em;
        height: 3.2em;
        background: var(--background_tint);
        border-radius: var(--border_md);
        font-size: 1em;
        color: inherit;
        resize: vertical;
        max-height: 7em;
      }
    }
    &__btn{
      position: relative;
      width: auto;
      padding: .9em 1em;
      display: flex;
      gap: .8em;
      align-items: center;
      background: var(--primary_rgba);
      border-radius: var(--border_md);
      font-size: 1em;
      &.is--disable{
        pointer-events: none;
        opacity: 0.4;
      }
    }
  }
  .kubini-product-comments{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .8em;
    margin-top: 2em;
  }
  
  .kubini-product-comment{
    position: relative;
    width: 100%;
    display: flex;
    padding: .8em;
    border-radius: var(--border_md);
    border: .04em solid var(--color_rgba);
    gap: 1em;
    &__user{
      width: 3em;
      height: 3em;
      border-radius: var(--border_ultra_xl);
      overflow: hidden;
      position: relative;
      background: var(--background);
      img{
        @extend %image-center;
      }
      @extend %flex-center;
      i{
        font-size: 1em;
      }
    }
    &__container{
      position: relative;
      width: calc(100% - 4em);
      display: flex;
      flex-direction: column;
      padding-bottom: 2em;
      
    }
    span{
      font-size: .8em;
      position: absolute;
      bottom: 0.8em;
      left: 0;
      opacity: .8;
    }
    button{
      position: absolute;
      bottom: 0.8em;
      right: 0;
      opacity: .8;
      text-decoration: underline;
      display: flex;
    }
  }
  
.kubini-product-comment{
  position: relative;
  width: 100%;
  display: flex;
  padding: .8em;
  border-radius: var(--border_md);
  border: .04em solid var(--color_rgba);
  gap: 1em;
  &__user{
    width: 3em;
    height: 3em;
    border-radius: var(--border_ultra_xl);
    overflow: hidden;
    position: relative;
    background: var(--background);
    img{
      @extend %image-center;
    }
    @extend %flex-center;
    i{
      font-size: 1em;
    }
  }
  &__container{
    position: relative;
    width: calc(100% - 4em);
    display: flex;
    flex-direction: column;
    padding-bottom: 2em;
    
  }
  span{
    font-size: .8em;
    position: absolute;
    bottom: 0.8em;
    left: 0;
    opacity: .8;
  }
  button{
    position: absolute;
    bottom: 0.8em;
    right: 0;
    opacity: .8;
    text-decoration: underline;
    display: flex;
  }
}


.kubini-home-products-more{
  position: relative;
  width: 100%;
  display: flex;
  gap: 1em;
  justify-content: center;
  opacity: .6;
  align-items: center;
  font-size: .9em;
  margin: 2em 0;
  span{
    white-space: nowrap;
  }

}

.kubini-home-products-more-line{
  position: relative;
  width: 100%;
  height: 0.06em;
  background: currentColor;
}
