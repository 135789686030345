@font-face {
    font-family: 'Lufga';
    src: url('Lufga-ExtraBold.eot');
    src: url('Lufga-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('Lufga-ExtraBold.woff2') format('woff2'),
        url('Lufga-ExtraBold.woff') format('woff'),
        url('Lufga-ExtraBold.ttf') format('truetype'),
        url('Lufga-ExtraBold.svg#Lufga-ExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('Lufga-ExtraLight.eot');
    src: url('Lufga-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Lufga-ExtraLight.woff2') format('woff2'),
        url('Lufga-ExtraLight.woff') format('woff'),
        url('Lufga-ExtraLight.ttf') format('truetype'),
        url('Lufga-ExtraLight.svg#Lufga-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('Lufga-Bold.eot');
    src: url('Lufga-Bold.eot?#iefix') format('embedded-opentype'),
        url('Lufga-Bold.woff2') format('woff2'),
        url('Lufga-Bold.woff') format('woff'),
        url('Lufga-Bold.ttf') format('truetype'),
        url('Lufga-Bold.svg#Lufga-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('Lufga-Italic.eot');
    src: url('Lufga-Italic.eot?#iefix') format('embedded-opentype'),
        url('Lufga-Italic.woff2') format('woff2'),
        url('Lufga-Italic.woff') format('woff'),
        url('Lufga-Italic.ttf') format('truetype'),
        url('Lufga-Italic.svg#Lufga-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('Lufga-ExtraLightItalic.eot');
    src: url('Lufga-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
        url('Lufga-ExtraLightItalic.woff2') format('woff2'),
        url('Lufga-ExtraLightItalic.woff') format('woff'),
        url('Lufga-ExtraLightItalic.ttf') format('truetype'),
        url('Lufga-ExtraLightItalic.svg#Lufga-ExtraLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('Lufga-BoldItalic.eot');
    src: url('Lufga-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Lufga-BoldItalic.woff2') format('woff2'),
        url('Lufga-BoldItalic.woff') format('woff'),
        url('Lufga-BoldItalic.ttf') format('truetype'),
        url('Lufga-BoldItalic.svg#Lufga-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('Lufga-SemiBoldItalic.eot');
    src: url('Lufga-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Lufga-SemiBoldItalic.woff2') format('woff2'),
        url('Lufga-SemiBoldItalic.woff') format('woff'),
        url('Lufga-SemiBoldItalic.ttf') format('truetype'),
        url('Lufga-SemiBoldItalic.svg#Lufga-SemiBoldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('Lufga-Light.eot');
    src: url('Lufga-Light.eot?#iefix') format('embedded-opentype'),
        url('Lufga-Light.woff2') format('woff2'),
        url('Lufga-Light.woff') format('woff'),
        url('Lufga-Light.ttf') format('truetype'),
        url('Lufga-Light.svg#Lufga-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('Lufga-Thin.eot');
    src: url('Lufga-Thin.eot?#iefix') format('embedded-opentype'),
        url('Lufga-Thin.woff2') format('woff2'),
        url('Lufga-Thin.woff') format('woff'),
        url('Lufga-Thin.ttf') format('truetype'),
        url('Lufga-Thin.svg#Lufga-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('Lufga-LightItalic.eot');
    src: url('Lufga-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('Lufga-LightItalic.woff2') format('woff2'),
        url('Lufga-LightItalic.woff') format('woff'),
        url('Lufga-LightItalic.ttf') format('truetype'),
        url('Lufga-LightItalic.svg#Lufga-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('Lufga-SemiBold.eot');
    src: url('Lufga-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Lufga-SemiBold.woff2') format('woff2'),
        url('Lufga-SemiBold.woff') format('woff'),
        url('Lufga-SemiBold.ttf') format('truetype'),
        url('Lufga-SemiBold.svg#Lufga-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('Lufga-ThinItalic.eot');
    src: url('Lufga-ThinItalic.eot?#iefix') format('embedded-opentype'),
        url('Lufga-ThinItalic.woff2') format('woff2'),
        url('Lufga-ThinItalic.woff') format('woff'),
        url('Lufga-ThinItalic.ttf') format('truetype'),
        url('Lufga-ThinItalic.svg#Lufga-ThinItalic') format('svg');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('Lufga-MediumItalic.eot');
    src: url('Lufga-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Lufga-MediumItalic.woff2') format('woff2'),
        url('Lufga-MediumItalic.woff') format('woff'),
        url('Lufga-MediumItalic.ttf') format('truetype'),
        url('Lufga-MediumItalic.svg#Lufga-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('Lufga-Regular.eot');
    src: url('Lufga-Regular.eot?#iefix') format('embedded-opentype'),
        url('Lufga-Regular.woff2') format('woff2'),
        url('Lufga-Regular.woff') format('woff'),
        url('Lufga-Regular.ttf') format('truetype'),
        url('Lufga-Regular.svg#Lufga-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lufga';
    src: url('Lufga-Medium.eot');
    src: url('Lufga-Medium.eot?#iefix') format('embedded-opentype'),
        url('Lufga-Medium.woff2') format('woff2'),
        url('Lufga-Medium.woff') format('woff'),
        url('Lufga-Medium.ttf') format('truetype'),
        url('Lufga-Medium.svg#Lufga-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

