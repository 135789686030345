
.icon-battery-empty:before { content: '\e800'; } /* '' */
.icon-volume-level-low:before { content: '\e801'; } /* '' */
.icon-cocktail-shaker:before { content: '\e802'; } /* '' */
.icon-battery-full:before { content: '\e803'; } /* '' */
.icon-battery-low:before { content: '\e804'; } /* '' */
.icon-bluetooth:before { content: '\e805'; } /* '' */
.icon-network:before { content: '\e806'; } /* '' */
.icon-connection-usb:before { content: '\e807'; } /* '' */
.icon-wifi:before { content: '\e808'; } /* '' */
.icon-controller-pad:before { content: '\e809'; } /* '' */
.icon-database-server:before { content: '\e80a'; } /* '' */
.icon-desktop-check:before { content: '\e80b'; } /* '' */
.icon-database:before { content: '\e80c'; } /* '' */
.icon-desktop-delete:before { content: '\e80d'; } /* '' */
.icon-battery-medium:before { content: '\e80e'; } /* '' */
.icon-keyboard:before { content: '\e80f'; } /* '' */
.icon-command:before { content: '\e810'; } /* '' */
.icon-option-keyboard:before { content: '\e811'; } /* '' */
.icon-previous-arrow-left:before { content: '\e812'; } /* '' */
.icon-return:before { content: '\e813'; } /* '' */
.icon-shift-up-arrow:before { content: '\e814'; } /* '' */
.icon-desktop-plus:before { content: '\e815'; } /* '' */
.icon-laptop-device:before { content: '\e816'; } /* '' */
.icon-controller-console:before { content: '\e817'; } /* '' */
.icon-android:before { content: '\e818'; } /* '' */
.icon-apple:before { content: '\e819'; } /* '' */
.icon-google:before { content: '\e81a'; } /* '' */
.icon-keyboard-alt:before { content: '\e81b'; } /* '' */
.icon-windows:before { content: '\e81c'; } /* '' */
.icon-twitter:before { content: '\e81d'; } /* '' */
.icon-youtube:before { content: '\e81e'; } /* '' */
.icon-webcam-video:before { content: '\e81f'; } /* '' */
.icon-drone:before { content: '\e820'; } /* '' */
.icon-planet:before { content: '\e821'; } /* '' */
.icon-telescope:before { content: '\e822'; } /* '' */
.icon-mouse:before { content: '\e823'; } /* '' */
.icon-mouse-1:before { content: '\e824'; } /* '' */
.icon-computer-screen-curve:before { content: '\e825'; } /* '' */
.icon-smart-watch:before { content: '\e826'; } /* '' */
.icon-printer-1:before { content: '\e827'; } /* '' */
.icon-robot:before { content: '\e828'; } /* '' */
.icon-computer-screen:before { content: '\e829'; } /* '' */
.icon-storage-hard-drive:before { content: '\e82a'; } /* '' */
.icon-smart-watch-2:before { content: '\e82b'; } /* '' */
.icon-storage-floppy-disk:before { content: '\e82c'; } /* '' */
.icon-facebook:before { content: '\e82d'; } /* '' */
.icon-chip:before { content: '\e82e'; } /* '' */
.icon-alien:before { content: '\e82f'; } /* '' */
.icon-webcam-video-off:before { content: '\e830'; } /* '' */
.icon-dna:before { content: '\e831'; } /* '' */
.icon-camera-video:before { content: '\e832'; } /* '' */
.icon-party-popper:before { content: '\e833'; } /* '' */
.icon-volume-level-off:before { content: '\e834'; } /* '' */
.icon-play:before { content: '\e835'; } /* '' */
.icon-pause:before { content: '\e836'; } /* '' */
.icon-music-note:before { content: '\e837'; } /* '' */
.icon--power-off:before { content: '\e838'; } /* '' */
.icon-music-note-1:before { content: '\e839'; } /* '' */
.icon-chess-piece-knight:before { content: '\e83a'; } /* '' */
.icon-speaker-1:before { content: '\e83b'; } /* '' */
.icon-music-note-equalizer:before { content: '\e83c'; } /* '' */
.icon-volume-down:before { content: '\e83d'; } /* '' */
.icon-film:before { content: '\e83e'; } /* '' */
.icon-recording-tape:before { content: '\e83f'; } /* '' */
.icon-volume-level-high:before { content: '\e840'; } /* '' */
.icon-speaker-2:before { content: '\e841'; } /* '' */
.icon-spin:before { content: '\e842'; } /* '' */
.icon-milk:before { content: '\e843'; } /* '' */
.icon-microphone:before { content: '\e844'; } /* '' */
.icon-tea-cup:before { content: '\e845'; } /* '' */
.icon-water-glass:before { content: '\e846'; } /* '' */
.icon-wine-bottle:before { content: '\e847'; } /* '' */
.icon-wine-glass:before { content: '\e848'; } /* '' */
.icon-drinks-cocktail:before { content: '\e849'; } /* '' */
.icon-ice-cream-1:before { content: '\e84a'; } /* '' */
.icon-drum-stick:before { content: '\e84b'; } /* '' */
.icon-volume-up:before { content: '\e84c'; } /* '' */
.icon-volume-off:before { content: '\e84d'; } /* '' */
.icon-burger:before { content: '\e84e'; } /* '' */
.icon-cake:before { content: '\e84f'; } /* '' */
.icon-sweet-food:before { content: '\e850'; } /* '' */
.icon-drinks-beer-mug:before { content: '\e851'; } /* '' */
.icon-candy-cane:before { content: '\e852'; } /* '' */
.icon-barbeque-pot:before { content: '\e853'; } /* '' */
.icon-cheese:before { content: '\e854'; } /* '' */
.icon-cherries:before { content: '\e855'; } /* '' */
.icon-coffee:before { content: '\e856'; } /* '' */
.icon-circle-flask:before { content: '\e857'; } /* '' */
.icon-spin3:before { content: '\e858'; } /* '' */
.icon-coffee-takeaway-cup:before { content: '\e859'; } /* '' */
.icon-milk-carton:before { content: '\e85a'; } /* '' */
.icon-kitchenware-serving:before { content: '\e85b'; } /* '' */
.icon-pizza:before { content: '\e85c'; } /* '' */
.icon-toast:before { content: '\e85d'; } /* '' */
.icon-ice-cream:before { content: '\e85e'; } /* '' */
.icon-chef-toque-hat:before { content: '\e85f'; } /* '' */
.icon-microwave:before { content: '\e860'; } /* '' */
.icon-fork-spoon:before { content: '\e861'; } /* '' */
.icon-popcorn:before { content: '\e862'; } /* '' */
.icon-kitchenware-induction-pot:before { content: '\e863'; } /* '' */
.icon-steak:before { content: '\e864'; } /* '' */
.icon-sushi:before { content: '\e865'; } /* '' */
.icon-medical-bag:before { content: '\e866'; } /* '' */
.icon-kitchenware-no:before { content: '\e867'; } /* '' */
.icon-refrigerator:before { content: '\e868'; } /* '' */
.icon-strawberry:before { content: '\e869'; } /* '' */
.icon-microphone-mute-off:before { content: '\e86a'; } /* '' */
.icon-volume-mute:before { content: '\e86b'; } /* '' */
.icon-image-photo-focus-center:before { content: '\e86c'; } /* '' */
.icon-photo-four:before { content: '\e86d'; } /* '' */
.icon-align-horizontal-center:before { content: '\e86e'; } /* '' */
.icon-syringe:before { content: '\e86f'; } /* '' */
.icon-warning-triangle:before { content: '\e870'; } /* '' */
.icon-align-horizontal-left:before { content: '\e871'; } /* '' */
.icon-flu-mask:before { content: '\e872'; } /* '' */
.icon-align-layers:before { content: '\e873'; } /* '' */
.icon-align-vertical-bottom:before { content: '\e874'; } /* '' */
.icon-medical-cross:before { content: '\e875'; } /* '' */
.icon-align-vertical-top:before { content: '\e876'; } /* '' */
.icon-image-flash-2:before { content: '\e877'; } /* '' */
.icon-interface-arrows-expand-1:before { content: '\e878'; } /* '' */
.icon-flash-1:before { content: '\e879'; } /* '' */
.icon-arrows-right-circle:before { content: '\e87a'; } /* '' */
.icon-arrows-shrink-1:before { content: '\e87b'; } /* '' */
.icon-interface-arrows-shrink:before { content: '\e87c'; } /* '' */
.icon-arrows-synchronize:before { content: '\e87d'; } /* '' */
.icon-arrows-up-circle:before { content: '\e87e'; } /* '' */
.icon-award-crown:before { content: '\e87f'; } /* '' */
.icon-health-medical-bandage:before { content: '\e880'; } /* '' */
.icon-health-medical-hospital-circle:before { content: '\e881'; } /* '' */
.icon-award-trophy:before { content: '\e882'; } /* '' */
.icon-gift:before { content: '\e883'; } /* '' */
.icon-accessories-film:before { content: '\e884'; } /* '' */
.icon-health-medical-heart-rate:before { content: '\e885'; } /* '' */
.icon-image-flash-off:before { content: '\e886'; } /* '' */
.icon-picture-flower:before { content: '\e887'; } /* '' */
.icon-block:before { content: '\e888'; } /* '' */
.icon-add:before { content: '\e889'; } /* '' */
.icon-image-picture-landscape:before { content: '\e88a'; } /* '' */
.icon-picture-gallery:before { content: '\e88b'; } /* '' */
.icon-alert-radio-active:before { content: '\e88c'; } /* '' */
.icon-alarm-bell-off:before { content: '\e88d'; } /* '' */
.icon-align-horizontal-right:before { content: '\e88e'; } /* '' */
.icon-arrows-expand:before { content: '\e88f'; } /* '' */
.icon-arrows-all-direction:before { content: '\e890'; } /* '' */
.icon-align-vertical-center:before { content: '\e891'; } /* '' */
.icon-camera:before { content: '\e892'; } /* '' */
.icon-interface-arrows-left-circle-1--arrow-keyboard-circle-button-left:before { content: '\e893'; } /* '' */
.icon-lenses:before { content: '\e894'; } /* '' */
.icon-add-square:before { content: '\e895'; } /* '' */
.icon-arrows-down-circle:before { content: '\e896'; } /* '' */
.icon-medical-capsule:before { content: '\e897'; } /* '' */
.icon-alarm-bell:before { content: '\e898'; } /* '' */
.icon-film-roll:before { content: '\e899'; } /* '' */
.icon-photo-focus-points:before { content: '\e89a'; } /* '' */
.icon-half-star:before { content: '\e89b'; } /* '' */
.icon-camera-circle:before { content: '\e89c'; } /* '' */
.icon-book:before { content: '\e89d'; } /* '' */
.icon-file:before { content: '\e89e'; } /* '' */
.icon-cursor-arrow-1:before { content: '\e89f'; } /* '' */
.icon-delete-bin:before { content: '\e8a0'; } /* '' */
.icon-download:before { content: '\e8a1'; } /* '' */
.icon-dashboard:before { content: '\e8a2'; } /* '' */
.icon-delete-square:before { content: '\e8a3'; } /* '' */
.icon-download-circle:before { content: '\e8a4'; } /* '' */
.icon-download-desktop:before { content: '\e8a5'; } /* '' */
.icon-binocular:before { content: '\e8a6'; } /* '' */
.icon-edit-brush:before { content: '\e8a7'; } /* '' */
.icon-color-drop-pick:before { content: '\e8a8'; } /* '' */
.icon-brush-2:before { content: '\e8a9'; } /* '' */
.icon-attachment:before { content: '\e8aa'; } /* '' */
.icon-edit-design-tool:before { content: '\e8ab'; } /* '' */
.icon-copy-clipboard:before { content: '\e8ac'; } /* '' */
.icon-delete-2:before { content: '\e8ad'; } /* '' */
.icon-content-fire:before { content: '\e8ae'; } /* '' */
.icon-cursor-hand:before { content: '\e8af'; } /* '' */
.icon-content-book-phone:before { content: '\e8b0'; } /* '' */
.icon-content-eye-glasses:before { content: '\e8b1'; } /* '' */
.icon-dashboard-layout-circle:before { content: '\e8b2'; } /* '' */
.icon-bookmark:before { content: '\e8b3'; } /* '' */
.icon-calendar-mark:before { content: '\e8b4'; } /* '' */
.icon-archive:before { content: '\e8b5'; } /* '' */
.icon-book-open:before { content: '\e8b6'; } /* '' */
.icon-cursor-arrow:before { content: '\e8b7'; } /* '' */
.icon-bookmark-tag:before { content: '\e8b8'; } /* '' */
.icon-calendar:before { content: '\e8b9'; } /* '' */
.icon-note-pad:before { content: '\e8ba'; } /* '' */
.icon-flll-color:before { content: '\e8bb'; } /* '' */
.icon-edit-pen-2:before { content: '\e8bc'; } /* '' */
.icon-edit-magnet:before { content: '\e8bd'; } /* '' */
.icon-quill:before { content: '\e8be'; } /* '' */
.icon-printer:before { content: '\e8bf'; } /* '' */
.icon-edit-ruler:before { content: '\e8c0'; } /* '' */
.icon-edit-scissors:before { content: '\e8c1'; } /* '' */
.icon-skull:before { content: '\e8c2'; } /* '' */
.icon-picker:before { content: '\e8c3'; } /* '' */
.icon-edit-write-2:before { content: '\e8c4'; } /* '' */
.icon-edit-magic-wand:before { content: '\e8c5'; } /* '' */
.icon-zoom-out:before { content: '\e8c6'; } /* '' */
.icon-zoom-in:before { content: '\e8c7'; } /* '' */
.icon-favorite-heart:before { content: '\e8c8'; } /* '' */
.icon-view-of:before { content: '\e8c9'; } /* '' */
.icon-favorite-star:before { content: '\e8ca'; } /* '' */
.icon-file-bookmark:before { content: '\e8cb'; } /* '' */
.icon-pin-2:before { content: '\e8cc'; } /* '' */
.icon-like:before { content: '\e8cd'; } /* '' */
.icon-file-check:before { content: '\e8ce'; } /* '' */
.icon-file-text:before { content: '\e8cf'; } /* '' */
.icon-view:before { content: '\e8d0'; } /* '' */
.icon-dislike:before { content: '\e8d1'; } /* '' */
.icon-help-customer-support-human:before { content: '\e8d2'; } /* '' */
.icon-help-customer-support:before { content: '\e8d3'; } /* '' */
.icon-question-square:before { content: '\e8d4'; } /* '' */
.icon-help-question-message:before { content: '\e8d5'; } /* '' */
.icon-hierarchy-node:before { content: '\e8d6'; } /* '' */
.icon-folder:before { content: '\e8d7'; } /* '' */
.icon-home-1:before { content: '\e8d8'; } /* '' */
.icon-home-3:before { content: '\e8d9'; } /* '' */
.icon-id-finger-print:before { content: '\e8da'; } /* '' */
.icon-id-thumb-mark:before { content: '\e8db'; } /* '' */
.icon-file-clipboard-text:before { content: '\e8dc'; } /* '' */
.icon-file-double:before { content: '\e8dd'; } /* '' */
.icon-id-face-scan:before { content: '\e8de'; } /* '' */
.icon-lighting-brightness-4:before { content: '\e8df'; } /* '' */
.icon-lighting-light-bulb:before { content: '\e8e0'; } /* '' */
.icon-lighting-light-bulb-on:before { content: '\e8e1'; } /* '' */
.icon-link:before { content: '\e8e2'; } /* '' */
.icon-lock-circle:before { content: '\e8e3'; } /* '' */
.icon-login:before { content: '\e8e4'; } /* '' */
.icon-loop-1:before { content: '\e8e5'; } /* '' */
.icon-link-broken:before { content: '\e8e6'; } /* '' */
.icon-loop-2:before { content: '\e8e7'; } /* '' */
.icon-home-2:before { content: '\e8e8'; } /* '' */
.icon-scroll-up-down:before { content: '\e8e9'; } /* '' */
.icon-scroll-left-righ:before { content: '\e8ea'; } /* '' */
.icon-lock:before { content: '\e8eb'; } /* '' */
.icon-remove-square:before { content: '\e8ec'; } /* '' */
.icon-presentation-projector:before { content: '\e8ed'; } /* '' */
.icon-search-circle:before { content: '\e8ee'; } /* '' */
.icon-search:before { content: '\e8ef'; } /* '' */
.icon-security-shield:before { content: '\e8f0'; } /* '' */
.icon-security-fire-wall:before { content: '\e8f1'; } /* '' */
.icon-dial-pad:before { content: '\e8f2'; } /* '' */
.icon-logout:before { content: '\e8f3'; } /* '' */
.icon-lighting-brightness-3:before { content: '\e8f4'; } /* '' */
.icon-file-zip:before { content: '\e8f5'; } /* '' */
.icon-security-shield-person:before { content: '\e8f6'; } /* '' */
.icon-setting-cog:before { content: '\e8f7'; } /* '' */
.icon-gauge-dashboard:before { content: '\e8f8'; } /* '' */
.icon-hammer:before { content: '\e8f9'; } /* '' */
.icon-hierarchy:before { content: '\e8fa'; } /* '' */
.icon-presentation-board:before { content: '\e8fb'; } /* '' */
.icon-help-customer-support-1:before { content: '\e8fc'; } /* '' */
.icon-setting-wrench:before { content: '\e8fd'; } /* '' */
.icon-security-shield-profile:before { content: '\e8fe'; } /* '' */
.icon-setting-menu:before { content: '\e8ff'; } /* '' */
.icon-setting-slider:before { content: '\e900'; } /* '' */
.icon-share:before { content: '\e901'; } /* '' */
.icon-voice:before { content: '\e902'; } /* '' */
.icon-menu:before { content: '\e903'; } /* '' */
.icon-mega-phone:before { content: '\e904'; } /* '' */
.icon-signal-graph:before { content: '\e905'; } /* '' */
.icon-satellite:before { content: '\e906'; } /* '' */
.icon-bold:before { content: '\e907'; } /* '' */
.icon-center-align:before { content: '\e908'; } /* '' */
.icon-filter:before { content: '\e909'; } /* '' */
.icon-eraser:before { content: '\e90a'; } /* '' */
.icon-font-size:before { content: '\e90b'; } /* '' */
.icon-italic:before { content: '\e90c'; } /* '' */
.icon-left-align:before { content: '\e90d'; } /* '' */
.icon-paragraph:before { content: '\e90e'; } /* '' */
.icon-list-bullets:before { content: '\e90f'; } /* '' */
.icon-right-align:before { content: '\e910'; } /* '' */
.icon-bar:before { content: '\e911'; } /* '' */
.icon-sigma:before { content: '\e912'; } /* '' */
.icon-to-speech:before { content: '\e913'; } /* '' */
.icon-underline:before { content: '\e914'; } /* '' */
.icon-time-alarm:before { content: '\e915'; } /* '' */
.icon-time-clock-circle:before { content: '\e916'; } /* '' */
.icon-time-hour-glass:before { content: '\e917'; } /* '' */
.icon-unlock:before { content: '\e918'; } /* '' */
.icon-upload-desktop:before { content: '\e919'; } /* '' */
.icon-user-circle:before { content: '\e91a'; } /* '' */
.icon-signal-square:before { content: '\e91b'; } /* '' */
.icon-upload-2:before { content: '\e91c'; } /* '' */
.icon-style:before { content: '\e91d'; } /* '' */
.icon-time-stop-watch-alternate:before { content: '\e91e'; } /* '' */
.icon-time-timer:before { content: '\e91f'; } /* '' */
.icon-quotation:before { content: '\e920'; } /* '' */
.icon-upload-circle:before { content: '\e921'; } /* '' */
.icon-send-email:before { content: '\e922'; } /* '' */
.icon-mail-send-envelope:before { content: '\e923'; } /* '' */
.icon-send-email-envelope:before { content: '\e924'; } /* '' */
.icon-mail-send-forward-email:before { content: '\e925'; } /* '' */
.icon-send-reply:before { content: '\e926'; } /* '' */
.icon-user-full-body:before { content: '\e927'; } /* '' */
.icon-user-multiple:before { content: '\e928'; } /* '' */
.icon-user-profile-focus:before { content: '\e929'; } /* '' */
.icon-user-single:before { content: '\e92a'; } /* '' */
.icon-validation-check:before { content: '\e92b'; } /* '' */
.icon-validation-check-circle:before { content: '\e92c'; } /* '' */
.icon-weather-celsius:before { content: '\e92d'; } /* '' */
.icon-humidity-none:before { content: '\e92e'; } /* '' */
.icon-fahrenheit:before { content: '\e92f'; } /* '' */
.icon-weather-lightning:before { content: '\e930'; } /* '' */
.icon-weather-rain-drop:before { content: '\e931'; } /* '' */
.icon-rain-cloud:before { content: '\e932'; } /* '' */
.icon-weather-snow-flake:before { content: '\e933'; } /* '' */
.icon-at:before { content: '\e934'; } /* '' */
.icon-weather-sun-1:before { content: '\e935'; } /* '' */
.icon-umbrella:before { content: '\e936'; } /* '' */
.icon-legal-justice-hammer:before { content: '\e937'; } /* '' */
.icon-legal-justice-scale:before { content: '\e938'; } /* '' */
.icon-mail-chat-bubble-oval:before { content: '\e939'; } /* '' */
.icon-chat-bubble-oval-check:before { content: '\e93a'; } /* '' */
.icon-chat-bubble-oval-question:before { content: '\e93b'; } /* '' */
.icon-chat-bubble-oval-delete--:before { content: '\e93c'; } /* '' */
.icon-mail-chat-bubble-oval-warning:before { content: '\e93d'; } /* '' */
.icon-chat-bubble-text-oval:before { content: '\e93e'; } /* '' */
.icon-chat-bubble-typing-oval:before { content: '\e93f'; } /* '' */
.icon-chat-two-bubbles-oval:before { content: '\e940'; } /* '' */
.icon-mail-inbox:before { content: '\e941'; } /* '' */
.icon-inbox-envelope-add:before { content: '\e942'; } /* '' */
.icon-inbox-envelope-outbox:before { content: '\e943'; } /* '' */
.icon-interface-weather-temperature--temperature-thermometer-weather-level-meter-mercury-measure:before { content: '\e944'; } /* '' */
.icon-weather-cloud:before { content: '\e945'; } /* '' */
.icon-mail-inbox-envelope-block--envelope-email-message-block-spam-remove:before { content: '\e946'; } /* '' */
.icon-inbox-envelope:before { content: '\e947'; } /* '' */
.icon-moon:before { content: '\e948'; } /* '' */
.icon-weather-snow:before { content: '\e949'; } /* '' */
.icon-weather-sun:before { content: '\e94a'; } /* '' */
.icon-hashtag:before { content: '\e94b'; } /* '' */
.icon-smiley-happy-face:before { content: '\e94c'; } /* '' */
.icon-smiley-sad-face:before { content: '\e94d'; } /* '' */
.icon-smiley-straight-face:before { content: '\e94e'; } /* '' */
.icon-atm-card:before { content: '\e94f'; } /* '' */
.icon-atm-card-1:before { content: '\e950'; } /* '' */
.icon-bank:before { content: '\e951'; } /* '' */
.icon-bill:before { content: '\e952'; } /* '' */
.icon-cash-briefcase:before { content: '\e953'; } /* '' */
.icon-dollar-coin:before { content: '\e954'; } /* '' */
.icon-coins-stack:before { content: '\e955'; } /* '' */
.icon-search-dollar:before { content: '\e956'; } /* '' */
.icon-cashier-bar-code:before { content: '\e957'; } /* '' */
.icon-qr-code:before { content: '\e958'; } /* '' */
.icon-dollar:before { content: '\e959'; } /* '' */
.icon-pound:before { content: '\e95a'; } /* '' */
.icon-cashier-calculator:before { content: '\e95b'; } /* '' */
.icon-euro:before { content: '\e95c'; } /* '' */
.icon-money-graph:before { content: '\e95d'; } /* '' */
.icon-yuan:before { content: '\e95e'; } /* '' */
.icon-graph-arrow-decrease:before { content: '\e95f'; } /* '' */
.icon-graph-arrow-increase:before { content: '\e960'; } /* '' */
.icon-money-safe-vault:before { content: '\e961'; } /* '' */
.icon-money-cash-target-dollar:before { content: '\e962'; } /* '' */
.icon-wallet-purse:before { content: '\e963'; } /* '' */
.icon-wallet:before { content: '\e964'; } /* '' */
.icon-recycle:before { content: '\e965'; } /* '' */
.icon-bone:before { content: '\e966'; } /* '' */
.icon-clover:before { content: '\e967'; } /* '' */
.icon-cat:before { content: '\e968'; } /* '' */
.icon-flower:before { content: '\e969'; } /* '' */
.icon-bitcoin:before { content: '\e96a'; } /* '' */
.icon-dog:before { content: '\e96b'; } /* '' */
.icon-barn:before { content: '\e96c'; } /* '' */
.icon-green-house:before { content: '\e96d'; } /* '' */
.icon-leaf:before { content: '\e96e'; } /* '' */
.icon-graph-pie-chart:before { content: '\e96f'; } /* '' */
.icon-pine-tree:before { content: '\e970'; } /* '' */
.icon-potted-cactus:before { content: '\e971'; } /* '' */
.icon-rice-field-sun:before { content: '\e972'; } /* '' */
.icon-rainbow:before { content: '\e973'; } /* '' */
.icon-shark:before { content: '\e974'; } /* '' */
.icon-tree-1:before { content: '\e975'; } /* '' */
.icon-tree-2:before { content: '\e976'; } /* '' */
.icon-tree:before { content: '\e977'; } /* '' */
.icon-volcano:before { content: '\e978'; } /* '' */
.icon-water-drop-1:before { content: '\e979'; } /* '' */
.icon-phone-mobile:before { content: '\e97a'; } /* '' */
.icon-phone-contact-phonebook:before { content: '\e97b'; } /* '' */
.icon-mode-airplane:before { content: '\e97c'; } /* '' */
.icon-signal-full:before { content: '\e97d'; } /* '' */
.icon-rose:before { content: '\e97e'; } /* '' */
.icon-telephone-circle:before { content: '\e97f'; } /* '' */
.icon-telephone-iphone:before { content: '\e980'; } /* '' */
.icon-telephone-ringing:before { content: '\e981'; } /* '' */
.icon-telephone-off:before { content: '\e982'; } /* '' */
.icon-application-add:before { content: '\e983'; } /* '' */
.icon-browser-search:before { content: '\e984'; } /* '' */
.icon-code-bug-security:before { content: '\e985'; } /* '' */
.icon-bug-threat-browser:before { content: '\e986'; } /* '' */
.icon-cloud:before { content: '\e987'; } /* '' */
.icon-cloud-download:before { content: '\e988'; } /* '' */
.icon-cloud-off:before { content: '\e989'; } /* '' */
.icon-cloud-upload:before { content: '\e98a'; } /* '' */
.icon-module:before { content: '\e98b'; } /* '' */
.icon-module-puzzle:before { content: '\e98c'; } /* '' */
.icon-module-three:before { content: '\e98d'; } /* '' */
.icon-rss-screen:before { content: '\e98e'; } /* '' */
.icon-rss-symbol:before { content: '\e98f'; } /* '' */
.icon-programming-script:before { content: '\e990'; } /* '' */
.icon-bug-threat-folder:before { content: '\e991'; } /* '' */
.icon-telephone:before { content: '\e992'; } /* '' */
.icon-script-code:before { content: '\e993'; } /* '' */
.icon-application-remove:before { content: '\e994'; } /* '' */
.icon-browser-multiple:before { content: '\e995'; } /* '' */
.icon-browser-code:before { content: '\e996'; } /* '' */
.icon-bug-threat-document:before { content: '\e997'; } /* '' */
.icon-css-three:before { content: '\e998'; } /* '' */
.icon-html-five:before { content: '\e999'; } /* '' */
.icon-web:before { content: '\e99a'; } /* '' */
.icon-religion-cross:before { content: '\e99b'; } /* '' */
.icon-religion-christianity:before { content: '\e99c'; } /* '' */
.icon-religion-islam:before { content: '\e99d'; } /* '' */
.icon-religion-symbol-peace:before { content: '\e99e'; } /* '' */
.icon-file-code:before { content: '\e99f'; } /* '' */
.icon-religion-yin-yang:before { content: '\e9a0'; } /* '' */
.icon-religion-judaism:before { content: '\e9a1'; } /* '' */
.icon-transfer-truck:before { content: '\e9a2'; } /* '' */
.icon-shipping-box:before { content: '\e9a3'; } /* '' */
.icon-box-fragile:before { content: '\e9a4'; } /* '' */
.icon-bag-hand:before { content: '\e9a5'; } /* '' */
.icon-suitcase:before { content: '\e9a6'; } /* '' */
.icon-building:before { content: '\e9a7'; } /* '' */
.icon-business-target:before { content: '\e9a8'; } /* '' */
.icon-shopping-cart:before { content: '\e9a9'; } /* '' */
.icon-shopping-cart-heavy:before { content: '\e9aa'; } /* '' */
.icon-ring:before { content: '\e9ab'; } /* '' */
.icon-dress:before { content: '\e9ac'; } /* '' */
.icon-ball:before { content: '\e9ad'; } /* '' */
.icon-shirt:before { content: '\e9ae'; } /* '' */
.icon-chair:before { content: '\e9af'; } /* '' */
.icon-backpack:before { content: '\e9b0'; } /* '' */
.icon-beanie:before { content: '\e9b1'; } /* '' */
.icon-tall-hat:before { content: '\e9b2'; } /* '' */
.icon-bow-tie:before { content: '\e9b3'; } /* '' */
.icon-furniture-closet:before { content: '\e9b4'; } /* '' */
.icon-sofa:before { content: '\e9b5'; } /* '' */
.icon-necktie:before { content: '\e9b6'; } /* '' */
.icon-gift-reward:before { content: '\e9b7'; } /* '' */
.icon-jewelry:before { content: '\e9b8'; } /* '' */
.icon-store-discount:before { content: '\e9b9'; } /* '' */
.icon-store-factory:before { content: '\e9ba'; } /* '' */
.icon-store-signage:before { content: '\e9bb'; } /* '' */
.icon-passport:before { content: '\e9bc'; } /* '' */
.icon-airport-landing:before { content: '\e9bd'; } /* '' */
.icon-shipping-box-sign:before { content: '\e9be'; } /* '' */
.icon-business-startup:before { content: '\e9bf'; } /* '' */
.icon-airport-baggage:before { content: '\e9c0'; } /* '' */
.icon-jewelry-gold:before { content: '\e9c1'; } /* '' */
.icon-business-summit:before { content: '\e9c2'; } /* '' */
.icon-travel-hotel-parking-sign--discount-coupon-parking-price-prices:before { content: '\e9c3'; } /* '' */
.icon-air-conditioner:before { content: '\e9c4'; } /* '' */
.icon-bed:before { content: '\e9c5'; } /* '' */
.icon-hanger:before { content: '\e9c6'; } /* '' */
.icon-hotel-bell:before { content: '\e9c7'; } /* '' */
.icon-bath-tub:before { content: '\e9c8'; } /* '' */
.icon-airport-take-off:before { content: '\e9c9'; } /* '' */
.icon-pet-paw:before { content: '\e9ca'; } /* '' */
.icon-hotel-dumbell:before { content: '\e9cb'; } /* '' */
.icon-cart:before { content: '\e9cc'; } /* '' */
.icon-pool-ladder:before { content: '\e9cd'; } /* '' */
.icon-map:before { content: '\e9ce'; } /* '' */
.icon-map-location:before { content: '\e9cf'; } /* '' */
.icon-navigation-arrow:before { content: '\e9d0'; } /* '' */
.icon-rectangle-flag:before { content: '\e9d1'; } /* '' */
.icon-earth:before { content: '\e9d2'; } /* '' */
.icon-map-triangle-flag:before { content: '\e9d3'; } /* '' */
.icon-anchor:before { content: '\e9d4'; } /* '' */
.icon-spa:before { content: '\e9d5'; } /* '' */
.icon-places-mountain:before { content: '\e9d6'; } /* '' */
.icon-places-street-sign:before { content: '\e9d7'; } /* '' */
.icon-snorkle:before { content: '\e9d8'; } /* '' */
.icon-transportation-airplane:before { content: '\e9d9'; } /* '' */
.icon-map-navigation-arrow-off:before { content: '\e9da'; } /* '' */
.icon-transportation-car:before { content: '\e9db'; } /* '' */
.icon-places-column:before { content: '\e9dc'; } /* '' */
.icon-shower-head:before { content: '\e9dd'; } /* '' */
.icon-beach:before { content: '\e9de'; } /* '' */
.icon-transportation-ship:before { content: '\e9df'; } /* '' */
.icon-camping-tent:before { content: '\e9e0'; } /* '' */
.icon-wayfinder-escalator-up:before { content: '\e9e1'; } /* '' */
.icon-hot-spring:before { content: '\e9e2'; } /* '' */
.icon-wayfinder-ladder:before { content: '\e9e3'; } /* '' */
.icon-wayfinder-escalator-down:before { content: '\e9e4'; } /* '' */
.icon-wayfinder-man:before { content: '\e9e5'; } /* '' */
.icon-wayfinder-lift:before { content: '\e9e6'; } /* '' */
.icon-wayfinder-toilet:before { content: '\e9e7'; } /* '' */
.icon-wayfinder-toilet-sign-man-woman:before { content: '\e9e8'; } /* '' */
.icon-travel-wayfinder-toiltet-sign-woman:before { content: '\e9e9'; } /* '' */
.icon-wayfinder-woman:before { content: '\e9ea'; } /* '' */
.icon-wayfinder-toilet-sign-man:before { content: '\e9eb'; } /* '' */
.icon-transportation-sail-ship:before { content: '\e9ec'; } /* '' */
.icon-wayfinder-lifebuoy:before { content: '\e9ed'; } /* '' */
.icon-map-location-pin:before { content: '\e9ee'; } /* '' */
.icon-wayfinder-fire-exit:before { content: '\e9ef'; } /* '' */
.icon-wayfinder-disability:before { content: '\e9f0'; } /* '' */
.icon-transportation-bus:before { content: '\e9f1'; } /* '' */
